.fl-item-color-tiles-container {
    margin-bottom: 3rem;

    .fl-item-color-tiles-list {
        padding-left: 0;
        list-style-type: none;
        overflow: hidden;

        .fl-item-color-tiles-list-item {
            margin: 10px 10px 10px 0;
            float: left;

            .fl-color-tile-label {
                border: 1px solid $gray-200;
                position: relative;
                cursor: pointer;

                svg {
                    width: 24px;
                    height: 24px;
                    margin: 3px;

                    path {
                        stroke: $black;
                        fill: $white;
                    }
                }
                .fl-color-tile-background {
                    width: 30px;
                    height: 30px;
                    text-align: center;

                    &:hover {
                        border: 1px solid $custom-file-border-color;
                    }
                }
                .fl-color-tile-image {
                    position: absolute;
                    left: 0;
                    z-index: 1;
                    width: 30px;
                    height: 30px;
                }
                .fl-color-tile-selected-image {
                    position: absolute;
                    z-index: 2;
                }
            }
        }
    }
}
