.fl-dropdown {
    .form-check-label {
        cursor: pointer;
    }

    .filter-badge {
        right: 0;
    }

    .fl-dropdown-container {
        cursor: pointer;
        position: relative;
        padding-left: 0;
        border: 2px solid $gray-200;
        margin-bottom: 2rem;

        .fl-dropdown-label {
            padding-left: 1rem;
        }

        .fl-dropdown-content {
            list-style-type: none;
            position: absolute;
            width: 100%;
            z-index: 2;
            background-color: $white;
            border: 1px solid #000;
            border-top: transparent;
            margin-top: 13px;
            padding-bottom: 1rem;
            max-height: 200px;
            overflow-y: auto;

            > .fl-dropdown-item {
                &:first-child {
                    margin-top: 1rem;
                }
            }

            .fl-dropdown-item {
                cursor: pointer;

                label {
                    cursor: pointer;
                    margin-bottom: 0;
                    padding-left: 1rem;
                }
            }
        }
    }
}

#filterCollapse,
.main-filters {
    .list-controls & {
        .page-content .card .fl-dropdown-container .fl-dropdown-content .fl-dropdown-item.form-check-label .filter-badge {
            position: relative;
            top: 7px;
            float: right;
            right: 10px;
        }
    }
}
